import React, { useState, useEffect, useRef } from 'react';
import "./Header.css";
import Logo_US from "../Media/Logo_partners/Logo_US.svg"
import Logo_US_unofficial from "../Media/Logo_partners/Logo_US_unofficial.svg"
import {useTranslation} from "react-i18next";


const FullWidthBlock1 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (window.innerWidth > 650) {
                setIsOpen(false); // Автоматически скрывает меню при увеличении экрана
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleItems = () => {
        setIsOpen(!isOpen);
    };

    const { t } = useTranslation();

    return (
        <div id="Header_body">


            <div id="Header" className={`full-width-block ${isOpen ? 'expanded' : 'collapsed'}`}>

                <div id="Media_block">
                    {/*<img src={Logo_US_unofficial} id="Logo_US_unofficial"/>*/}
                    <img src={Logo_US} id="Logo_s"/>
                    <p id="Name_US">UniSafe</p>

                    <a href="/" className="Link">
                        <div>
                            {t('Header.Back')}
                        </div>
                    </a>
                </div>
            </div>

            <div id="Header_border">
            </div>
        </div>
    );
};

export default FullWidthBlock1;
