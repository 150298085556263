import React, {useEffect, useRef} from 'react';
import "./Start_Block.css";
import Gradient from "../Media/Gradient.svg"
import {useTranslation} from "react-i18next";


function Start_Block() {

    const { t } = useTranslation();

    return (
        <div id="Gradient_Block">

            <img src={Gradient} id="Gradient"/>
            <div id="Gradient_text">
                <h1>UniSafe</h1>
                <p>{t('General.Title_1')}<br/>{t('General.Title_2')}</p>
            </div>
        </div>
    )
}

export default Start_Block;