import "./Design.css";
import React from "react";
import {useTranslation} from "react-i18next";

export function PointerGroup() {
    return (
        <div>
            <div className="Pointer_General__Design Pointer_group_1_General__Design"></div>
            <div className="Pointer_General__Design Pointer_group_2_General__Design"></div>
            <div className="Pointer_General__Design Pointer_group_3_General__Design"></div>
            <div className="Pointer_General__Design Pointer_group_4_General__Design"></div>
            <div className="Pointer_General__Design Pointer_group_5_General__Design"></div>
        </div>
    )
}

export function EnterSite({Link}) {
    const { t } = useTranslation();
    return (
        <div>
            {Link && (
                <a href={Link}>
                    <p>{t('General.Link')}</p>
                </a>
            )}
        </div>
    )
}

function Type_1({Link}) {

    const style = {
        width: Link ? Link : '50%'
    };

    return (
        <div className="Content_block_General__Design">
            <div className="Border_block_TR_General__Design Border_block_1__Type1"></div>
            <div className="Border_block_RB_General__Design Border_block_2__Type1" style={style}></div>
            <div className="Border_block_LT_General__Design Border_block_3__Type1" style={style}></div>
            <div className="Pointer_General__Design Pointer_top__Type1"></div>
            <div className="Pointer_group__Type1"><PointerGroup/></div>
            <div className="Pointer_General__Design Pointer_bottom__Type1"></div>
            <div className="Button_site_General__Design Button_site__Type1"><EnterSite Link={Link}/></div>
        </div>
    )
}

function Type_2({ Link }) {

    const borderClass = Link ? "Border_block_1__Type2" : "Border_block_1_noLink__Type2";
    const pointerClass = Link ? "Pointer_bottom__Type2" : "Pointer_bottom_noLink__Type2";

    return (
        <div className="Content_block_General__Design">
            <div className={`Border_block_BL_General__Design ${borderClass}`}></div>
            <div className="Pointer_General__Design Pointer_top__Type2"></div>
            <div className={`Pointer_General__Design ${pointerClass}`}></div>
            <div className="Button_site_General__Design Button_site__Type2"><EnterSite Link={Link} />
            </div>
        </div>
    );
}
function Type_3({Link}) {

    const borderClass = Link ? "Border_block_3__Type3" : "Border_block_3_noLink__Type3";
    const pointerClass = Link ? "Pointer_bottom__Type3" : "Pointer_bottom_noLink__Type3";

    return (
        <div className="Content_block_General__Design">
            <div className="Border_block_TR_General__Design Border_block_1__Type3"></div>
            <div className="Border_block_RB_General__Design Border_block_2__Type3"></div>
            <div className={`Border_block_Line_General__Design ${borderClass}`}></div>
            <div className="Pointer_General__Design Pointer_top__Type3"></div>
            <div className="Pointer_group__Type3"><PointerGroup/></div>
            <div className={`Pointer_General__Design ${pointerClass}`}></div>
            <div className="Button_site_General__Design Button_site__Type3"><EnterSite Link={Link}/></div>
        </div>
    )
}

function Type_4() {

    return (
        <div className="Content_block_General__Design">
            <div className="Border_block_TR_General__Design Border_block_1__Type4"></div>
            <div className="Border_block_LT_General__Design Border_block_2__Type4"></div>
            <div className="Border_block_BL_General__Design Border_block_3__Type4"></div>
            <div className="Border_block_TR_General__Design Border_block_4__Type4"></div>
            <div className="Border_block_RB_General__Design Border_block_5__Type4"></div>
            <div className="Pointer_General__Design Pointer_1__Type4"></div>
            <div className="Pointer_General__Design Pointer_2__Type4"></div>
            <div className="Pointer_General__Design Pointer_3__Type4"></div>
            <div className="Pointer_General__Design Pointer_4__Type4"></div>
            <div className="Pointer_group__Type4"><PointerGroup/></div>
        </div>
    )
}

function Type_5() {

    return (
        <div className="Content_block_General__Design">
            <div className="Border_block_RB_General__Design Border_block_1__Type5"></div>
            <div className="Border_block_LT_General__Design Border_block_2__Type5"></div>
            <div className="Border_block_BL_General__Design Border_block_3__Type5"></div>
            <div className="Pointer_General__Design Pointer_1__Type5"></div>
            <div className="Pointer_General__Design Pointer_2__Type5"></div>
            <div className="Pointer_group__Type5"><PointerGroup/></div>
        </div>
    )
}

function Type_6() {

    return (
        <div className="Content_block_General__Design">
            <div className="Border_block_BL_General__Design Border_block_1__Type6"></div>
            <div className="Border_block_Line_General__Design Border_block_2__Type6"></div>
            <div className="Border_block_TR_General__Design Border_block_3__Type6"></div>
            <div className="Border_block_RB_General__Design Border_block_4__Type6"></div>

            <div className="Pointer_General__Design Pointer_1__Type6"></div>
            <div className="Pointer_General__Design Pointer_2__Type6"></div>
            <div className="Pointer_General__Design Pointer_3__Type6"></div>
            <div className="Pointer_General__Design Pointer_4__Type6"></div>
        </div>
    )
}


const Types = {
    1: Type_1,
    2: Type_2,
    3: Type_3,
    4: Type_4,
    5: Type_5,
    6: Type_6,
};

export default function Design({TypeDesign, Link}) {
    const Component = Types[TypeDesign];

    return (
        <Component Link={Link} />
    )
}

