import './App.css';
import Header from "./Background/Header"
import End from "./Content/End";
import {AvorMegafon} from "./Content/Content";
import Design from "./Content/Design";
import Header_2 from "./Background/Header_2";

const headingText3 = "Антивор";
const Link_3 = "/antivor/";
const Price_3 = ["2900"];


function AvorPageMegafon() {

    return (
        <div className="App">
            <header>
                <Header_2/>
            </header>

            <div id="Body">
            <div style={{paddingTop: '150px'}}>
                <AvorMegafon
                    Design={Design}
                    TypeDesign={'3'}
                    Link={Link_3}
                    headingText={headingText3}
                    Price={Price_3}
                />
            </div>

            <End />
            </div>
        </div>
    );
}

export default AvorPageMegafon;
