import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import Design from "./Content/Design";
import Header_2 from "./Background/Header_2";
import {useTranslation} from "react-i18next";
import Logo_CyberPro from "./Media/Logo_products/Logo_CyberPro.svg";
import Logo_Android from "./Media/Logo_Android.svg";
import Logo_AppStore from "./Media/Logo_AppStore.svg";
import Logo_Windows from "./Media/Logo_Windows.svg";


import "./Content/Content.css";
import "./Content/End.css";
import TestImg from "./Media/TEST.png";

const headingText3 = "Антивор";
const Price_3 = ["2900"];


function FileDownloader({ url, href, fileName, imgName, text, Type}) {
    const handleClick = () => {
        const link = document.createElement('a');
        // Определяем, какой атрибут использовать для скачивания файла
        link.href = url || href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const buttonClasses = {
        1: "Download_button_1",
        2: "Download_button_2",
        3: "Download_button_3",
    };

    const buttonClass = buttonClasses[Type]

    if (url === 'none' || href === 'none') {
        return null;
    }

    return (
        <button onClick={handleClick} className={buttonClass}>
            {imgName && <img src={imgName} alt={fileName} className="Download_OS"/>}
            {text && <p>{text}</p>}
        </button>
    );
}

function CreatePage() {
    const { t } = useTranslation();
    const [productData, setProductData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('i');

        if (token) {
            fetch(`https://unisafe.ru/api/product?token=${token}`)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setProductData(data);
                    } else {
                        setError('Product not found');
                    }
                })
                .catch(error => {
                    setError('Error fetching product data');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setError('No token provided');
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }


    return (
        <div className="App">
            <header>
                <Header_2/>
            </header>

            <div id="Body">
                <div style={{paddingTop: '150px'}}>
                    <div className="Main_block">
                        {productData.page_name  !== 'none' && <h1 className="Heading">{productData.page_name}</h1>}
                        <div className="Content_block" style={{ marginLeft: '0'}} >
                            {productData.topic !== 'none' && <h2 className="Subtitle">{productData.topic}</h2>}

                            {productData.body_1 !== 'none' && <p className="Text_body">{productData.body_1}</p>}
                            {productData.body_2 !== 'none' && <p className="Text_body">{productData.body_2}</p>}
                            {productData.body_3 !== 'none' && <p className="Text_body">{productData.body_3}</p>}
                            {productData.body_4 !== 'none' && <p className="Text_body">{productData.body_4}</p>}

                            <div className="Download">
                                {productData.salary!== 'none' && (
                                    <div className="Download_flex" style={{justifyContent: 'flex-start'}}>
                                        {/*<img src={Logo_CyberPro} className="Download_Logo"/>*/}
                                        <div className="Buy" style={{margin: '0'}}>
                                            <h1>Стоимость</h1>
                                            <h2>{productData.salary}</h2>
                                        </div>
                                    </div>
                                )}
                                <div className="Download_flex Download_flex_vertical">
                                    <FileDownloader url={productData.link_android} fileName="Android" imgName={Logo_Android} Type={1}/>
                                    <FileDownloader url={productData.link_apple} fileName="Apple" imgName={Logo_AppStore} Type={1}/>
                                    <FileDownloader url={productData.link_windows} fileName="Winodws" imgName={Logo_Windows} Type={1}/>
                                </div>
                                <FileDownloader href={productData.button_1_link} text={productData.button_1_name} Type={2}/>
                                <FileDownloader href={productData.button_2_link} text={productData.button_2_name} Type={2}/>
                                <FileDownloader href={productData.button_3_link} text={productData.button_3_name} Type={2}/>
                                <FileDownloader href={productData.button_4_link} text={productData.button_4_name} Type={2}/>
                            </div>

                        </div>

                        <div className="Design">
                            <Design TypeDesign={'3'}/>
                        </div>
                    </div>
                </div>

                <div id="Main_block__EndBlock" >
                    <div id="Content_block_1__EndBlock">
                        <div id="String__EndBlock">
                            {productData.company_name !== 'none' && <p>{productData.company_name}</p>}
                            {productData.company_enn !== 'none' && <p>{productData.company_enn}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreatePage;
