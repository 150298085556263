import './App.css';
import Squares from "./Background/Squares";
import Header from "./Background/Header"
import Start_Block from "./Background/Start_Block";
import End from "./Content/End";
import Product_Blocks from "./Content/Product_Blocks";
import Info_Blocks from "./Content/Info_Blocks";
import React from "react";

import ContactForm from './ContactForm';


import './i18n';

function MainPage() {

    return (
        <div className="App">
            <header>
                <Header/>
            </header>

            <div id="Body">
                <Squares/>
                <Start_Block/>
                <Product_Blocks/>
                <Info_Blocks/>
                <End />
            </div>
        </div>
    );
}

export default MainPage;
