import './App.css';
import Header from "./Background/Header"
import End from "./Content/End";
import {AvorDownloadUsafe} from "./Content/Content";
import Design from "./Content/Design";
import Header_2 from "./Background/Header_2";


function AvorDownloadUsafePage() {

    return (
        <div className="App">
            <header>
                <Header_2/>
            </header>

            <div id="Body" style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                <div style={{paddingTop: '150px'}}>
                    <AvorDownloadUsafe
                        Design={Design}
                        TypeDesign={'3'}/>
                </div>

                <div>
                    <End />
                </div>
            </div>
        </div>
    );
}

export default AvorDownloadUsafePage;
