import React, {useEffect, useRef} from 'react';
import "./Squares.css";
import Logo_CyberPro_W from "../Media/Logo_products/Logo_CyberPro_White.svg"
import Logo_Avor_W from "../Media/Logo_products/Logo_Avor_White.svg"
import Logo_LateProtection_W from "../Media/Logo_products/Logo_LateProtection_White.svg"
import Logo_ProtectionStation_W from "../Media/Logo_products/Logo_ProtectionStation_White.svg"
import Logo_CloverMoney_W from "../Media/Logo_products/Logo_CloverMoney_White.svg"
import Logo_UnisafeSmart_W from "../Media/Logo_products/Logo_UnisafeSmart_White.svg"
import Logo_UnisafeTech_W from "../Media/Logo_products/Logo_UnisafeTech_White.svg"
import Logo_KidsProtection_W from "../Media/Logo_products/Logo_KidsProtection_White.svg"
import Logo_ALock_W from "../Media/Logo_products/Logo_ALock_White.svg"
import Logo_HiTechProtection_W from "../Media/Logo_products/Logo_HiTechProtection_White.svg"
import Logo_UniCare_W from "../Media/Logo_products/Logo_UniCare_White.svg"
import Logo_UniCarePlus_W from "../Media/Logo_products/Logo_UniCarePlus_White.svg"
import {CloverMoney, UniCare, UniCarePlus} from "../Content/Content";

function App_2() {

    const Move_block_Ref = useRef(null);

    useEffect(() => {
        var Move_block = Move_block_Ref.current;

        //Задаем рандомные координаты
        if (Move_block) {
            var W = Move_block.offsetWidth;
            var H = Move_block.offsetHeight;
            var W1 = Move_block.offsetWidth;
            var H1 = Move_block.offsetHeight;

            var w = new Array(4);
            var h = new Array(4);

            for (var l = 1; l <= 4; l++) {
                var Block = document.getElementsByClassName("Block_type_" + l);

                w[l - 1] = Block[0].offsetWidth;
                h[l - 1] = Block[0].offsetHeight;
            }

            if (W < (w[0] * 2 + w[1] * 3 + w[2] * 4 + w[3] * 5 + 13 * 10)) {
                W = (w[0] * 2 + w[1] * 3 + w[2] * 4 + w[3] * 5 + 13 * 10);
            }
            // console.log("ширина ", W, W1);

            if (H < (h[0] * 2 + h[1] * 3 + h[2] * 4 + h[3] * 5 + 13 * 10)) {
                H = (h[0] * 2 + h[1] * 3 + h[2] * 4 + h[3] * 5 + 13 * 10);
            }
            // console.log("длина ", H, H1);


            var Block_coord_H = new Array(4);
            var Block_coord_W = new Array(4);

            for (var l = 1; l <= 4; l++) {
                Block = document.getElementsByClassName("Block_type_" + l);

                Block_coord_H[l - 1] = new Array(Math.pow((l + 1), 2));
                Block_coord_W[l - 1] = new Array(Math.pow((l + 1), 2));

                var k = 0;

                var Nx = l + 1;
                var Ny = l + 1;

                var Inter = 0;

                for (var i = 0; i <= l; i++) {
                    for (var j = 0; j <= l; j++) {
                        Block_coord_H[l - 1][k] = (Math.floor(Math.random() * (H / Ny - h[l - 1])) + (H / Ny) * j) / H;
                        Block_coord_W[l - 1][k] = (Math.floor(Math.random() * (W / Nx - w[l - 1])) + (W / Nx) * i) / W;

                        Inter = 0

                        for (var l_dop = 1; l_dop < l; l_dop++) {
                            var k_max = Block_coord_H[l_dop - 1].length;

                            for (var k_dop = 0; k_dop < k_max; k_dop++) {
                                var Dif_H = (Block_coord_H[l - 1][k] - Block_coord_H[l_dop - 1][k_dop]) * H;
                                var Dif_W = (Block_coord_W[l - 1][k] - Block_coord_W[l_dop - 1][k_dop]) * W;

                                if ((Dif_H > -h[l-1] - 10) && (Dif_H < h[l_dop-1] + 10) && (Dif_W > -w[l-1] - 10) && (Dif_W < w[l_dop-1] + 10)) {
                                    Inter++;
                                }
                            }
                        }

                        // console.log(Inter)

                        if (Inter === 0) {
                            Block[k].style.top = Block_coord_H[l-1][k] * H1 + 'px';
                            Block[k].style.left = Block_coord_W[l-1][k] * W1 + 'px';
                            k++;
                        } else {
                            j--;
                        }


                    }
                }
            }

            //Пересчет координат при изменении размера окна
            window.addEventListener('resize', function() {
                // console.log("Размер окна изменен");

                W = Move_block.offsetWidth;
                H = Move_block.offsetHeight;

                for (var l = 1; l <= 4; l++) {
                    var Block = document.getElementsByClassName("Block_type_" + l);
                    k = 0;

                    for (var i = 0; i <= l; i++) {
                        for (var j = 0; j <= l; j++) {
                            Block[k].style.top = Block_coord_H[l - 1][k] * H + 'px';
                            Block[k].style.left = Block_coord_W[l - 1][k] * W + 'px';
                            k++;
                        }
                    }
                }
            });
        }
    }, []);



    // Отслеживаем положение мышки и рассчитываем движение блоков
    // useEffect(() => {
    //     var Move_block = Move_block_Ref.current;
    //
    //     if (Move_block) {
    //         var W = Move_block.offsetWidth;
    //         var H = Move_block.offsetHeight;
    //
    //         var Layer_1 = document.getElementById("Layer_1");
    //         var Layer_2 = document.getElementById("Layer_2");
    //         var Layer_3 = document.getElementById("Layer_3");
    //         var Layer_4 = document.getElementById("Layer_4");
    //
    //         function move(Layer) {
    //
    //             var X = document.getElementById('X');
    //             var Y = document.getElementById('Y');
    //
    //             function pos(e){
    //                 // console.log("мышка сдвинулась")
    //                 X.value = e.pageX;
    //                 Y.value = e.pageY;
    //
    //                 var y = Move_block.offsetTop;
    //                 var x = Move_block.offsetLeft;
    //
    //                 y = y + H / 2;
    //                 x = x + W / 2;
    //                 var t = 0;
    //
    //                 if (Layer === Layer_1) {
    //                     t = 0.2
    //                 }
    //                 if (Layer === Layer_2) {
    //                     t = 0.1
    //                 }
    //                 if (Layer === Layer_3) {
    //                     t = 0.05
    //                 }
    //                 if (Layer === Layer_4) {
    //                     t = 0.025
    //                 }
    //
    //
    //                 // var easing = 'cubic-bezier(0.3, 0.1, 0.7, 0.9)';
    //                 // var speedMultiplier = 10;
    //                 // Layer.style.transition = `top ${speedMultiplier}s ${easing}, left ${speedMultiplier}s ${easing}`;
    //
    //                 Layer.style.top = y - H / 2 + (Y.value - y - window.scrollY) * t + 'px';
    //                 Layer.style.left = x - W / 2 + (X.value - x) * t + 'px';
    //             }
    //             window.addEventListener('mousemove', pos, false);
    //         }
    //         move(Layer_1);
    //         move(Layer_2);
    //         move(Layer_3);
    //         move(Layer_4);
    //     }
    // }, []);

    useEffect(() => {
        const Move_block = Move_block_Ref.current;

        if (Move_block) {
            const W = Move_block.offsetWidth;
            const H = Move_block.offsetHeight;

            var y = Move_block.offsetTop;
            var x = Move_block.offsetLeft;

            const Layer_1 = document.getElementById("Layer_1");
            const Layer_2 = document.getElementById("Layer_2");
            const Layer_3 = document.getElementById("Layer_3");
            const Layer_4 = document.getElementById("Layer_4");

            const layers = [Layer_1, Layer_2, Layer_3, Layer_4];
            const factors = [0.2, 0.1, 0.05, 0.025];

            let targetX = x + W / 2;
            let targetY = y + H / 2;
            let currentX = targetX;
            let currentY = targetY;

            // Функция для плавного замедления движения
            function easeOutQuad(t) {
                return t * (2 - t);
            }

            // Анимация, которая обновляет позиции слоев
            function animate() {
                // Плавное движение к целевой позиции
                currentX += (targetX - currentX) * 0.05;
                currentY += (targetY - currentY) * 0.05;

                layers.forEach((layer, index) => {
                    const factor = factors[index];
                    const x = Move_block.offsetLeft + W / 2;
                    const y = Move_block.offsetTop + H / 2;

                    // Обновление позиции слоя без учета прокрутки
                    layer.style.top = y - H / 2 + (currentY - y) * factor + 'px';
                    layer.style.left = x - W / 2 + (currentX - x) * factor + 'px';
                });

                // Запрос следующего кадра анимации
                requestAnimationFrame(animate);
            }

            // Обработчик движения мыши
            function pos(e) {
                targetX = e.clientX;
                targetY = e.clientY;
            }

            // Добавление обработчиков событий
            window.addEventListener('mousemove', pos, false);
            requestAnimationFrame(animate);

            // Удаление обработчиков событий при размонтировании компонента
            return () => {
                window.removeEventListener('mousemove', pos, false);
            };
        }
    }, []);







    //Блюрим фон
    useEffect(() => {
        var Move_block = Move_block_Ref.current;

        if (Move_block) {
            var W = Move_block.offsetWidth;
            var H = Move_block.offsetHeight;

            var Layer_1 = document.getElementById("Layer_1");
            var Layer_2 = document.getElementById("Layer_2");
            var Layer_3 = document.getElementById("Layer_3");
            var Layer_4 = document.getElementById("Layer_4");

            var Block_type_1 = document.getElementsByClassName("Block_type_1");
            var Block_type_2 = document.getElementsByClassName("Block_type_2");
            var Block_type_3 = document.getElementsByClassName("Block_type_3");
            var Block_type_4 = document.getElementsByClassName("Block_type_4");

            var Opacity_min = 0.4

            function Blur(Layer) {

                var X = document.getElementById('X');
                var Y = document.getElementById('Y');

                    function f(e){

                    var Win_Height = window.innerHeight;
                    var Scroll = window.scrollY

                    var max_blur = 20;

                    if (Scroll < Win_Height/3) {
                        Layer.style.filter = "blur(0px)";
                        for (var i = 0; i < Block_type_1.length; i++) {
                            Block_type_1[i].style.backgroundColor = "rgb(5, 149, 60)";
                        }
                        for (var i = 0; i < Block_type_2.length; i++) {
                            Block_type_2[i].style.backgroundColor = "rgb(5, 149, 60)";
                        }
                        for (var i = 0; i < Block_type_3.length; i++) {
                            Block_type_3[i].style.backgroundColor = "rgb(5, 149, 60)";
                        }
                        for (var i = 0; i < Block_type_4.length; i++) {
                            Block_type_4[i].style.backgroundColor = "rgb(5, 149, 60)";
                        }
                    }

                    if ((Scroll >= Win_Height/3) && (Scroll < Win_Height * 4/3)) {
                        var blur = (Scroll - Win_Height/3) / (Win_Height) * max_blur;
                        Layer.style.filter = "blur(" + blur + "px)";
                        Layer.style.opacity = 1 - (Scroll - Win_Height/3) / (Win_Height) * (1 - Opacity_min);

                        var R = 5 + 144 * (Scroll - Win_Height/3) / Win_Height;
                        var G = 149;
                        var B = 60 + 85 * (Scroll - Win_Height/3) / Win_Height;
                        for (var i = 0; i < Block_type_1.length; i++) {
                            Block_type_1[i].style.backgroundColor = "rgb(" + R + ", " + G + ", " + B + ")";
                        }
                        for (var i = 0; i < Block_type_2.length; i++) {
                            Block_type_2[i].style.backgroundColor = "rgb(" + R + ", " + G + ", " + B + ")";
                        }
                        for (var i = 0; i < Block_type_3.length; i++) {
                            Block_type_3[i].style.backgroundColor = "rgb(" + R + ", " + G + ", " + B + ")";
                        }
                        for (var i = 0; i < Block_type_4.length; i++) {
                            Block_type_4[i].style.backgroundColor = "rgb(" + R + ", " + G + ", " + B + ")";
                        }

                    }

                    if (Scroll >= Win_Height * 4/3) {
                        Layer.style.filter = "blur(" + max_blur + "px)";
                        Layer.style.opacity = Opacity_min;
                        for (var i = 0; i < Block_type_1.length; i++) {
                            Block_type_1[i].style.backgroundColor = "rgb(149, 149, 149)";
                        }
                        for (var i = 0; i < Block_type_2.length; i++) {
                            Block_type_2[i].style.backgroundColor = "rgb(149, 149, 149)";
                        }
                        for (var i = 0; i < Block_type_3.length; i++) {
                            Block_type_3[i].style.backgroundColor = "rgb(149, 149, 149)";
                        }
                        for (var i = 0; i < Block_type_4.length; i++) {
                            Block_type_4[i].style.backgroundColor = "rgb(149, 149, 149)";
                        }
                    }

                }
                window.addEventListener('scroll', f, false);
            }
            Blur(Layer_1);
            Blur(Layer_2);
            Blur(Layer_3);
            Blur(Layer_4);
        }
    }, []);

    //Рандомные картинки
    const logos = [
        Logo_CyberPro_W,
        Logo_Avor_W,
        Logo_LateProtection_W,
        Logo_ProtectionStation_W,
        Logo_CloverMoney_W,
        Logo_UnisafeSmart_W,
        Logo_UnisafeTech_W,
        Logo_KidsProtection_W,
        Logo_ALock_W,
        Logo_HiTechProtection_W,
        Logo_UniCare_W,
        Logo_UniCarePlus_W
    ];

    const logos_1 = [
        Logo_CyberPro_W,
        Logo_UniCarePlus_W,
        Logo_CloverMoney_W,
        Logo_ProtectionStation_W
    ];

    const getRandomLogo = () => {
        const randomIndex = Math.floor(Math.random() * logos.length);
        return logos[randomIndex];
    };

    return (
        <div id="Body_Squares">
            <div id="Move_block" ref={Move_block_Ref}>
                <p type="text" id="X"></p>
                <p type="text" id="Y"></p>

                <div id="Layer_4" className="Layer">
                    {[...Array(25)].map((_, index) => (
                        <div key={index} className="Block_type_4">
                            {/*{index + 1}*/}
                        </div>
                    ))}
                </div>

                <div id="Layer_3" className="Layer">
                    {[...Array(16)].map((_, index) => (
                        <div key={index} className="Block_type_3">
                            {/*{index + 1}*/}
                        </div>
                    ))}
                </div>

                <div id="Layer_2" className="Layer">
                    {[...Array(9)].map((_, index) => (
                        <div key={index} className="Block_type_2">
                            <img src={getRandomLogo()} alt={`Logo ${index + 1}`} className="Logo_Squares" style={{opacity: '0.4'}}/>
                        </div>
                    ))}
                </div>

                <div id="Layer_1" className="Layer">
                    {logos_1.map((logo, index) => (
                        <div key={index} className="Block_type_1">
                            <img src={logo} alt={`Logo ${index + 1}`} className="Logo_Squares" style={{opacity: '0.6'}} />
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );

}

export default App_2;