import { useEffect } from 'react';

function RedirectToLicense() {
    useEffect(() => {
        // Переадресация на указанный URL при загрузке компонента
        window.location.href = 'https://unisafe.ru/files/License-agreement-Avor.pdf';
    }, []);

    return null; // Ничего не рендерим
}

export default RedirectToLicense;
