import React, { useState, useEffect } from 'react';
import "./Header.css";
import Logo_US from "../Media/Logo_partners/Logo_US.svg";
import { useTranslation } from "react-i18next";
import Earth from "../Media/Icon_Earth.svg";
import CryptoJS from "crypto-js";
import CloseIcon from '@mui/icons-material/Close';
import {PointerGroup} from "../Content/Design"



const Modal = ({ isOpen, closeModal, formType }) => {
    const [contactMethod, setContactMethod] = useState('email');
    const [contactDetails, setContactDetails] = useState('');
    const [body, setBody] = useState('');
    const [name, setName] = useState('');
    const { t } = useTranslation();


    if (!isOpen) return null;

    const getTitle = () => {
        switch (formType) {
            case 'partner':
                return t('Header.PartnerForm');
            case 'client':
                return t('Header.ClientForm');
            default:
                return t('Header.Default');
        }
    };

    const getPlaceholder = () => {
        switch (contactMethod) {
            case 'email':
                return 'yourMail@mail.ru';
            case 'phone':
                return '+7 (***) *** - ** - **';
            case 'telegram':
                return '@yourName';
            default:
                return '';
        }
    };

    const formatPhoneNumber = (value) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        let formatted = '+7 (';

        if (cleaned.length > 1) {
            formatted += cleaned.substring(1, 4);
        }
        if (cleaned.length >= 4) {
            formatted += ') ' + cleaned.substring(4, 7);
        }
        if (cleaned.length >= 7) {
            formatted += ' - ' + cleaned.substring(7, 9);
        }
        if (cleaned.length >= 9) {
            formatted += ' - ' + cleaned.substring(9, 11);
        }

        return formatted;
    };

    const handleContactDetailsChange = (e) => {
        let value = e.target.value;
        if (contactMethod === 'phone') {
            value = formatPhoneNumber(value);
        }
        setContactDetails(value);
    };

    const handleKeyDown = (e) => {
        if (contactMethod === 'phone' && (e.key === 'Backspace' || e.key === 'Delete')) {
            e.preventDefault();
            const cleaned = contactDetails.replace(/\D/g, '');
            const newValue = cleaned.slice(0, -1);
            setContactDetails(formatPhoneNumber(newValue));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const secretPart = 'kiQHwb9123b';
        const emailBody = `
            Обращение от ${formType === 'partner' ? 'партнера' : 'клиента'}
            Имя: ${name}
            Способ связи: ${contactMethod === 'phone' ? 'телефон' : contactMethod === 'email' ? 'почта' : 'телеграм'}
            Контактные данные: ${contactDetails}
            Сообщение: ${body}
        `;
        const token = CryptoJS.MD5(emailBody + secretPart).toString();

        try {
            const response = await fetch(`https://unisafe.ru/sendMail/send?token=${token}&body=${encodeURIComponent(emailBody)}`);
            const data = await response.json();
            if (data.success) {
                alert('Письмо успешно отправлено!');
                setBody(''); // Очищаем форму при успешной отправке
                closeModal();
            } else {
                // alert('Ошибка при отправке письма.');
                alert(emailBody);
            }
        } catch (error) {
            console.error('Ошибка:', error);
            alert('Ошибка при отправке письма.');
        }
    };

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>

                <div className="FlexBox">
                    <div className="Border_block_1"></div>
                    <div className="Border_block_2"></div>
                    <div className="Pointer_modal Pointer_1"></div>
                    <div className="Pointer_modal Pointer_2"></div>
                    <div className="Pointer_group_modal">
                        <div className="Pointer_modal Pointer_group_1_modal"></div>
                        <div className="Pointer_modal Pointer_group_2_modal"></div>
                        <div className="Pointer_modal Pointer_group_3_modal"></div>
                        <div className="Pointer_modal Pointer_group_4_modal"></div>
                        <div className="Pointer_modal Pointer_group_5_modal"></div>
                    </div>



                    <h2 className="Title">{getTitle()}</h2>
                    <form onSubmit={handleSubmit} className="Forma">
                        <div className="FlexDynamic">
                            <label className="Label">{t('Header.Name')}</label>
                            <input
                                className="Block_input"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <label className="Label">{t('Header.Method')}</label>
                            <select className="Block_input" style={{color: 'white', textAlign: 'center'}} name="contactMethod" onChange={(e) => setContactMethod(e.target.value)}>
                                <option value="phone">{t('Header.Phone')}</option>
                                <option value="email">{t('Header.Email')}</option>
                                <option value="telegram">{t('Header.TG')}</option>
                            </select>
                        </div>

                        <div className="FlexDynamic">
                            <label className="Label">{t('Header.Contact_info')}</label>
                            <input
                                className="Block_input"
                                type="text"
                                name="contactDetails"
                                placeholder={getPlaceholder()}
                                value={contactDetails}
                                onChange={handleContactDetailsChange}
                                onKeyDown={handleKeyDown}
                                onFocus={() => {
                                    if (contactMethod === 'phone' && contactDetails === '') {
                                        setContactDetails('+7 (');
                                    }
                                }}
                            />
                        </div>

                        <div className="FlexDynamic" style={{height: '50%'}}>
                            <label className="Label">{t('Header.Massage')}</label>
                            <textarea
                                style={{height: '100%'}}
                                className="Block_input"
                                name="body"
                                placeholder="Введите текст письма"
                                value={body}
                                onChange={(e) => setBody(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="ButtonEnter">{t('Header.Enter')}</button>
                    </form>
                    <button className="ButtonClose" onClick={closeModal}><CloseIcon style={{width: '70%', color: 'white'}} /></button>
                </div>
            </div>
        </div>
    );
};


function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [show, setShow] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setShow(false);
    };

    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            setShow(false);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className="LanguageSwitcher"
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            <div className="ButtonSwitcher">
                <img src={Earth} alt="Earth" />
            </div>
            {show && (
                <div className="MenuSwitcher">
                    <button className="ButtonLanguage" onClick={() => changeLanguage('en')}>English</button>
                    <button className="ButtonLanguage" onClick={() => changeLanguage('ru')}>Русский</button>
                </div>
            )}
        </div>
    );
}

const FullWidthBlock = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (window.innerWidth > 650) {
                setIsOpen(false); // Автоматически скрывает меню при увеличении экрана
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleItems = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formType, setFormType] = useState('');
    const openModal = (type) => {
        setFormType(type);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [body, setBody] = useState('');


    return (
        <div id="Header_body">
            <LanguageSwitcher />

            <div id="Header" className={`full-width-block ${isOpen ? 'expanded' : 'collapsed'}`} onMouseLeave={closeMenu}>
                <div id="Media_block">
                    <img src={Logo_US} id="Logo_s" />
                    <p id="Name_US">UniSafe</p>
                    <button id="Menu_button" className={`toggle-button ${isOpen || windowWidth > 650 ? 'hide' : ''}`} onClick={toggleItems}>
                        {isOpen ? t('Header.Close') : t('Header.Menu')}
                    </button>
                </div>

                <div id="Link_block" className={`items-list ${isOpen || windowWidth > 650 ? 'show' : ''}`}>
                    <a href="#About" className="Link" onClick={closeMenu}>
                        <p>{t('Header.About')}</p>
                    </a>
                    <a href="#Servises" className="Link" onClick={closeMenu}>
                        <p>{t('Header.Services')}</p>
                    </a>
                    <a href="#Contacts" className="Link" onClick={closeMenu}>
                        <p>{t('Header.Contacts')}</p>
                    </a>
                    <a href="#" className="Link" onClick={(e) => { e.preventDefault(); openModal('partner'); }}>
                        <p>{t('Header.For_partners')}</p>
                    </a>
                    <a href="#" className="Link" onClick={(e) => { e.preventDefault(); openModal('client'); }}>
                        <p>{t('Header.For_clients')}</p>
                    </a>
                </div>
            </div>

            <div id="Header_border"></div>

            <Modal
                isOpen={isModalOpen}
                closeModal={closeModal}
                formType={formType}
            />
        </div>
    );
};

export default FullWidthBlock;