import Design from "./Design";
// import {Content_test} from "./Content";
import {Content_test, CyberProtection} from "./Content";
import {ProtectionStation} from "./Content";
import {Avor} from "./Content";
import {KidsProtection} from "./Content";
import {LateProtection} from "./Content";
import {HiTechProtection} from "./Content";
import {SalesTechnology} from "./Content";
import {UniCare} from "./Content";
import {SavePrecious} from "./Content";
import {UniCarePlus} from "./Content";
import {ALock} from "./Content";
import {UniSafeSmart} from "./Content";
import {CloverMoney} from "./Content";
import {UniSafeTech} from "./Content";
import React from "react";

function Products() {

    const Price_1 = ["390", "3900"];

    const Link_2 = "https://buyps.ru/";

    const Link_3 = "/antivor/";
    const Price_3 = ["2900"];

    const Link_4 = "https://usafe-kids.ru/";
    const Price_4 = ["190", "1900"];

    const Link_5 = "https://ltpt.ru/";
    const Price_5 = ["от 1000"]

    const Link_6 = "/hitech/";
    const Price_6 = ["От 550"];


    const Link_8 = "https://care-usafe.ru/";
    const Price_8 = ["180", "1800"];

    const Price_9 = ["190", "1900"];

    const Price_10 = ["190", "1900"];

    const Link_11 = "/alock/";
    const Price_11 = ["90", "900"];

    const Link_12 = "/smart/";

    const Link_13 = "/clovermoney/";

    const Link_14 = "/tech/";

    return (
        <div className="App">

            {/*<Content_test*/}
            {/*    Design={Design}*/}
            {/*    TypeDesign={'1'}*/}
            {/*    headingText={headingText1}*/}
            {/*    Price={Price_1}*/}
            {/*/>*/}

            <CyberProtection
                Design={Design}
                TypeDesign={'1'}
                Price={Price_1}
            />

            <ProtectionStation
                Design={Design}
                TypeDesign={'2'}
                Link={Link_2}
            />

            <Avor
                Design={Design}
                TypeDesign={'3'}
                Link={Link_3}
                Price={Price_3}
            />

            <KidsProtection
                Design={Design}
                TypeDesign={'2'}
                Link={Link_4}
                Price={Price_4}
            />

            <LateProtection
                Design={Design}
                TypeDesign={'1'}
                Link={Link_5}
                Price={Price_5}
            />

            <HiTechProtection
                Design={Design}
                TypeDesign={'2'}
                Link={Link_6}
                Price={Price_6}
            />

            <SalesTechnology
                Design={Design}
                TypeDesign={'3'}
            />

            <UniCare
                Design={Design}
                TypeDesign={'2'}
                Link={Link_8}
                Price={Price_8}
            />

            <SavePrecious
                Design={Design}
                TypeDesign={'1'}
                Price={Price_9}
            />

            <UniCarePlus
                Design={Design}
                TypeDesign={'2'}
                Price={Price_10}
            />

            <ALock
                Design={Design}
                TypeDesign={'3'}
                Link={Link_11}
                Price={Price_11}
            />

            <UniSafeSmart
                Design={Design}
                TypeDesign={'2'}
                Link={Link_12}
            />

            <CloverMoney
                Design={Design}
                TypeDesign={'1'}
                Link={Link_13}
            />

            <UniSafeTech
                Design={Design}
                TypeDesign={'2'}
                Link={Link_14}
            />

        </div>
    );
}

export default Products;