import './App.css';
import Header from "./Background/Header"
import End from "./Content/End";
import {ProtectionStation} from "./Content/Content";
import Design from "./Content/Design";
import Header_2 from "./Background/Header_2";

const Link_2 = "https://buyps.ru/";


function ProtectionStationPage() {

    return (
        <div className="App">
            <header>
                <Header_2/>
            </header>

            <div id="Body">
            <div style={{paddingTop: '150px'}}>
                <ProtectionStation
                    Design={Design}
                    TypeDesign={'3'}
                    Link={Link_2}
                />
            </div>

            <End />
            </div>
        </div>
    );
}

export default ProtectionStationPage;
