import Design from "./Design";
import {About} from "./Content";
import {Partners} from "./Content";
import {Contacts} from "./Content";
import React from "react";


function Info() {

    return (
        <div className="App">
            <About
                Design={Design}
                TypeDesign={'4'}
                headingText={'О группе кампаний UniSafe'}
            />

            <Partners
                Design={Design}
                TypeDesign={'5'}
                headingText={'Партнеры'}
            />

            <Contacts
                Design={Design}
                TypeDesign={'6'}
                headingText={'Контакты'}
            />

        </div>
    );
}

export default Info;