import './App.css';
import Header from "./Background/Header"
import End from "./Content/End";
import {Avor, CyberProtectionFAQ, CyberProtectionSupport} from "./Content/Content";
import Design from "./Content/Design";
import Header_2 from "./Background/Header_2";

function CyberProtectionSupportPage() {

    return (
        <div className="App">
            <header>
                <Header_2/>
            </header>

            <div id="Body">
            <div style={{paddingTop: '150px'}}>
                <CyberProtectionSupport
                    Design={Design}
                    TypeDesign={'2'}
                />
            </div>

            <End />
            </div>
        </div>
    );
}

export default CyberProtectionSupportPage;
